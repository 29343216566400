
import { watch, computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api'

import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { dateDashNotationToDotWithForm, dateDotNotationToDashWithForm } from '@/utils/convertDate'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'
import { handleError } from '@/utils/handleError'
import { isRequired } from '@/utils/validation'

import { useCreateApplication, useUpdateApplication } from '@/api/application'
import { useGetTenderingsBasic } from '@/api/tendering'
import { useGetApplicantsBasic } from '@/api/applicant'
import { useGetEnumItemsBasic } from '@/api/enumItem'

import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { isOfType } from '@/utils/types/isOfType'
import { ApplicationOutput, ApplicationInput } from '@/api/types/application'
import { cloneDeep } from 'lodash-es'

export default defineComponent({
  name: 'AddEditApplicationDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    applicationToEdit: {
      type: Object as PropType<ApplicationOutput>,
      default: null,
    },
  },
  setup: (props, { emit, root }) => {
    const isEditMode = computed(() => Boolean(props.applicationToEdit))

    const {
      exec: getApplicantsBasic,
      data: applicants,
      isLoading: isLoadingApplicantsBasic,
    } = useGetApplicantsBasic()
    getApplicantsBasic()

    const {
      exec: getTenderingsBasic,
      data: tenderings,
      isLoading: isLoadingTenderingsBasic,
    } = useGetTenderingsBasic()
    getTenderingsBasic()

    const {
      exec: getEnumItemsBasic,
      data: enumItemsBasic,
      isLoading: isLoadingEnumItemsBasic,
    } = useGetEnumItemsBasic()
    getEnumItemsBasic({ params: { enumItemAssignment: 'APPLICATION_STATUS' } })

    const FORM_FIELDS: FormField[] = [
      {
        value: 'applicant',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => applicants.value ?? []),
        isLoading: computed(() => isLoadingApplicantsBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.applications.form.applicant') as string)],
      },
      {
        value: 'tendering',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => tenderings.value ?? []),
        isLoading: computed(() => isLoadingTenderingsBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.applications.form.tendering') as string)],
      },
      {
        value: 'offerPrice',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [],
      },
      {
        value: 'status',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => enumItemsBasic.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.applications.form.status') as string)],
      },
      {
        value: 'interviewDate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'feedback',
        fieldType: FORM_FIELDS_ENUM.TEXTAREA,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
    ]

    const form = ref<ApplicationInput | ApplicationOutput>(
      isEditMode.value ? cloneDeep(props.applicationToEdit) : ({} as ApplicationInput)
    )

    const { createApplication, isLoading: isLoadingCreateApplication } = useCreateApplication()

    async function onAdd(): Promise<void> {
      if (!isOfType<ApplicationOutput>(form.value, 'id')) {
        let updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, form.value)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

        try {
          await createApplication(updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateApplication, isLoading: isLoadingUpdateApplication } = useUpdateApplication()

    async function onEdit(): Promise<void> {
      if (isOfType<ApplicationOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm<ApplicationOutput>(form.value)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

        try {
          await updateApplication(updatedForm.id, updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditApplication = computed(
      () => isLoadingCreateApplication.value || isLoadingUpdateApplication.value
    )

    watch(
      () => props.applicationToEdit,
      () => {
        if (props.applicationToEdit) {
          form.value = props.applicationToEdit

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
      },
      {
        immediate: true,
      }
    )

    function close(): void {
      emit('added-edited')

      emit('close')
    }

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,

        FORM_FIELDS,

        DATA_TYPE,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditApplication,
      },
      listeners: {
        onAdd,

        onEdit,
      },
    })
  },
})
